export default function Zero() {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.02742 14.26C5.91809 14.26 4.87809 13.9913 3.90742 13.454C2.93676 12.9167 2.14809 12.1453 1.54142 11.14C0.952089 10.1173 0.657422 8.91267 0.657422 7.526C0.657422 6.13933 0.952089 4.94333 1.54142 3.938C2.14809 2.91533 2.93676 2.12667 3.90742 1.572C4.87809 1.01733 5.91809 0.739999 7.02742 0.739999C8.13676 0.739999 9.16809 1.01733 10.1214 1.572C11.0921 2.12667 11.8721 2.91533 12.4614 3.938C13.0508 4.94333 13.3454 6.13933 13.3454 7.526C13.3454 8.91267 13.0508 10.1173 12.4614 11.14C11.8721 12.1453 11.0921 12.9167 10.1214 13.454C9.16809 13.9913 8.13676 14.26 7.02742 14.26ZM7.02742 12.258C7.73809 12.258 8.41409 12.076 9.05542 11.712C9.69676 11.348 10.2254 10.8107 10.6414 10.1C11.0574 9.38933 11.2654 8.53133 11.2654 7.526C11.2654 6.52067 11.0574 5.66267 10.6414 4.952C10.2428 4.24133 9.71409 3.69533 9.05542 3.314C8.41409 2.93267 7.73809 2.742 7.02742 2.742C6.31676 2.742 5.63209 2.93267 4.97342 3.314C4.31476 3.678 3.77742 4.21533 3.36142 4.926C2.94542 5.63667 2.73742 6.50333 2.73742 7.526C2.73742 8.53133 2.94542 9.38933 3.36142 10.1C3.77742 10.8107 4.31476 11.348 4.97342 11.712C5.63209 12.076 6.31676 12.258 7.02742 12.258Z"
        fill="currentcolor"
      />
    </svg>
  );
}
