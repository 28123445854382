export default function Location() {
  return (
    <svg
      width="17"
      height="20"
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.50086 10.9417C9.99963 10.9417 11.2146 9.72668 11.2146 8.22791C11.2146 6.72915 9.99963 5.51416 8.50086 5.51416C7.0021 5.51416 5.78711 6.72915 5.78711 8.22791C5.78711 9.72668 7.0021 10.9417 8.50086 10.9417Z"
        stroke="white"
        strokeWidth="1.5"
      />
      <path
        d="M1.21115 6.64496C2.92464 -0.887449 14.0841 -0.878751 15.7889 6.65366C16.7891 11.0722 14.0406 14.8123 11.6313 17.126C9.88298 18.8134 7.11704 18.8134 5.36006 17.126C2.95943 14.8123 0.210885 11.0635 1.21115 6.64496Z"
        stroke="white"
        strokeWidth="1.5"
      />
    </svg>
  );
}

export function LocationTwo() {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99377 10.1461C9.39262 10.1461 10.5266 9.0283 10.5266 7.64946C10.5266 6.27061 9.39262 5.15283 7.99377 5.15283C6.59493 5.15283 5.46094 6.27061 5.46094 7.64946C5.46094 9.0283 6.59493 10.1461 7.99377 10.1461Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M1.19707 6.1933C2.79633 -0.736432 13.2118 -0.72843 14.803 6.2013C15.7365 10.2663 13.1712 13.7072 10.9225 15.8357C9.29079 17.3881 6.70924 17.3881 5.06939 15.8357C2.8288 13.7072 0.263493 10.2583 1.19707 6.1933Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  );
}
