export function SvgBgSm() {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.1322 1.5092C26.6141 -0.304124 29.3859 -0.304124 30.8678 1.5092C31.9895 2.88181 33.9265 3.26711 35.4881 2.42825C37.5511 1.32004 40.1119 2.38076 40.7871 4.62313C41.2981 6.32052 42.9403 7.41778 44.704 7.24036C47.0341 7.00599 48.994 8.96594 48.7596 11.296C48.5822 13.0597 49.6795 14.7019 51.3769 15.2129C53.6192 15.8881 54.68 18.4489 53.5718 20.5119C52.7329 22.0735 53.1182 24.0105 54.4908 25.1322C56.3041 26.6141 56.3041 29.3859 54.4908 30.8678C53.1182 31.9895 52.7329 33.9265 53.5718 35.4881C54.68 37.5511 53.6192 40.1119 51.3769 40.7871C49.6795 41.2981 48.5822 42.9403 48.7596 44.704C48.994 47.0341 47.0341 48.994 44.704 48.7596C42.9403 48.5822 41.2981 49.6795 40.7871 51.3769C40.1119 53.6192 37.5511 54.68 35.4881 53.5718C33.9265 52.7329 31.9895 53.1182 30.8678 54.4908C29.3859 56.3041 26.6141 56.3041 25.1322 54.4908C24.0105 53.1182 22.0735 52.7329 20.5119 53.5718C18.4489 54.68 15.8881 53.6192 15.2129 51.3769C14.7019 49.6795 13.0597 48.5822 11.296 48.7596C8.96594 48.994 7.00599 47.0341 7.24036 44.704C7.41778 42.9403 6.32052 41.2981 4.62313 40.7871C2.38076 40.1119 1.32004 37.5511 2.42825 35.4881C3.26711 33.9265 2.88181 31.9895 1.5092 30.8678C-0.304124 29.3859 -0.304124 26.6141 1.5092 25.1322C2.88181 24.0105 3.26711 22.0735 2.42825 20.5119C1.32004 18.4489 2.38076 15.8881 4.62313 15.2129C6.32052 14.7019 7.41778 13.0597 7.24036 11.296C7.00599 8.96593 8.96593 7.00599 11.296 7.24036C13.0597 7.41778 14.7019 6.32052 15.2129 4.62313C15.8881 2.38076 18.4489 1.32004 20.5119 2.42825C22.0735 3.26711 24.0105 2.88181 25.1322 1.5092Z"
        fill="#e6b745"
      />
      <path
        d="M25.5194 1.82559C26.8012 0.257078 29.1988 0.257078 30.4806 1.82559C31.7537 3.38351 33.9523 3.82084 35.7247 2.86872C37.5092 1.91013 39.7243 2.82764 40.3083 4.76728C40.8883 6.69382 42.7522 7.93922 44.7541 7.73785C46.7695 7.53512 48.4649 9.23046 48.2621 11.2459C48.0608 13.2478 49.3062 15.1117 51.2327 15.6917C53.1724 16.2757 54.0899 18.4908 53.1313 20.2753C52.1792 22.0477 52.6165 24.2463 54.1744 25.5194C55.7429 26.8012 55.7429 29.1988 54.1744 30.4806C52.6165 31.7537 52.1792 33.9523 53.1313 35.7247C54.0899 37.5092 53.1724 39.7243 51.2327 40.3083C49.3062 40.8883 48.0608 42.7522 48.2621 44.7541C48.4649 46.7695 46.7695 48.4649 44.7541 48.2621C42.7522 48.0608 40.8883 49.3062 40.3083 51.2327C39.7243 53.1724 37.5092 54.0899 35.7247 53.1313C33.9523 52.1792 31.7537 52.6165 30.4806 54.1744C29.1988 55.7429 26.8012 55.7429 25.5194 54.1744C24.2463 52.6165 22.0477 52.1792 20.2753 53.1313C18.4908 54.0899 16.2757 53.1724 15.6917 51.2327C15.1117 49.3062 13.2478 48.0608 11.2459 48.2621C9.23046 48.4649 7.53512 46.7695 7.73785 44.7541C7.93922 42.7522 6.69382 40.8883 4.76728 40.3083C2.82764 39.7243 1.91013 37.5092 2.86872 35.7247C3.82084 33.9523 3.38351 31.7537 1.82559 30.4806C0.257078 29.1988 0.257078 26.8012 1.82559 25.5194C3.38351 24.2463 3.82084 22.0477 2.86872 20.2753C1.91013 18.4908 2.82764 16.2757 4.76728 15.6917C6.69382 15.1117 7.93922 13.2478 7.73785 11.2459C7.53512 9.23046 9.23046 7.53512 11.2459 7.73785C13.2478 7.93922 15.1117 6.69382 15.6917 4.76728C16.2757 2.82764 18.4908 1.91013 20.2753 2.86872C22.0477 3.82084 24.2463 3.38351 25.5194 1.82559Z"
        stroke="white"
        strokeOpacity="0.12"
      />
    </svg>
  );
}

export default function SvgBg() {
  return (
    <svg
      className="svg-bg"
      xmlns="http://www.w3.org/2000/svg"
      width="130"
      height="130"
      viewBox="0 0 130 130"
      fill="none"
    >
      <path
        d="M58.3086 3.18813C61.7663 -1.04295 68.2337 -1.04295 71.6914 3.18813V3.18813C74.3088 6.39089 78.8286 7.28993 82.4723 5.33258V5.33258C87.286 2.74677 93.2611 5.22176 94.8365 10.454V10.454C96.0289 14.4145 99.8606 16.9748 103.976 16.5608V16.5608C109.413 16.014 113.986 20.5872 113.439 26.024V26.024C113.025 30.1394 115.585 33.9711 119.546 35.1635V35.1635C124.778 36.7389 127.253 42.714 124.667 47.5277V47.5277C122.71 51.1714 123.609 55.6912 126.812 58.3086V58.3086C131.043 61.7663 131.043 68.2337 126.812 71.6914V71.6914C123.609 74.3088 122.71 78.8286 124.667 82.4723V82.4723C127.253 87.286 124.778 93.2611 119.546 94.8365V94.8365C115.585 96.0289 113.025 99.8606 113.439 103.976V103.976C113.986 109.413 109.413 113.986 103.976 113.439V113.439C99.8606 113.025 96.0289 115.585 94.8365 119.546V119.546C93.2611 124.778 87.286 127.253 82.4723 124.667V124.667C78.8286 122.71 74.3088 123.609 71.6914 126.812V126.812C68.2337 131.043 61.7663 131.043 58.3086 126.812V126.812C55.6912 123.609 51.1714 122.71 47.5277 124.667V124.667C42.714 127.253 36.7389 124.778 35.1635 119.546V119.546C33.9711 115.585 30.1394 113.025 26.024 113.439V113.439C20.5872 113.986 16.014 109.413 16.5608 103.976V103.976C16.9748 99.8606 14.4145 96.0289 10.454 94.8365V94.8365C5.22176 93.2611 2.74677 87.286 5.33258 82.4723V82.4723C7.28993 78.8286 6.39089 74.3088 3.18813 71.6914V71.6914C-1.04295 68.2337 -1.04295 61.7663 3.18813 58.3086V58.3086C6.39089 55.6912 7.28993 51.1714 5.33258 47.5277V47.5277C2.74677 42.714 5.22176 36.7389 10.454 35.1635V35.1635C14.4145 33.9711 16.9748 30.1394 16.5608 26.024V26.024C16.014 20.5872 20.5872 16.014 26.024 16.5608V16.5608C30.1394 16.9748 33.9711 14.4145 35.1635 10.454V10.454C36.7389 5.22176 42.714 2.74676 47.5277 5.33258V5.33258C51.1714 7.28993 55.6912 6.39089 58.3086 3.18813V3.18813Z"
        fill="currentcolor"
      />
      <path
        d="M47.2911 5.77305C51.1456 7.84365 55.927 6.89259 58.6957 3.50452C61.9534 -0.481752 68.0466 -0.481752 71.3043 3.50452C74.073 6.89259 78.8544 7.84365 82.7089 5.77305C87.2441 3.33685 92.8735 5.66865 94.3577 10.5981C95.6191 14.7879 99.6725 17.4963 104.026 17.0583C109.148 16.5431 113.457 20.8517 112.942 25.9739C112.504 30.3275 115.212 34.3809 119.402 35.6423C124.331 37.1265 126.663 42.7559 124.227 47.2911C122.156 51.1456 123.107 55.927 126.495 58.6957C130.482 61.9534 130.482 68.0466 126.495 71.3043C123.107 74.073 122.156 78.8544 124.227 82.7089C126.663 87.2441 124.331 92.8735 119.402 94.3577C115.212 95.6191 112.504 99.6725 112.942 104.026C113.457 109.148 109.148 113.457 104.026 112.942C99.6725 112.504 95.6191 115.212 94.3577 119.402C92.8735 124.331 87.2441 126.663 82.7089 124.227C78.8544 122.156 74.073 123.107 71.3043 126.495C68.0466 130.482 61.9534 130.482 58.6957 126.495C55.927 123.107 51.1456 122.156 47.2911 124.227C42.7559 126.663 37.1265 124.331 35.6423 119.402C34.3809 115.212 30.3275 112.504 25.9739 112.942C20.8517 113.457 16.5431 109.148 17.0583 104.026C17.4963 99.6725 14.7879 95.6191 10.5981 94.3577C5.66864 92.8735 3.33685 87.2441 5.77305 82.7089C7.84365 78.8544 6.89259 74.073 3.50452 71.3043C-0.481752 68.0466 -0.481752 61.9534 3.50452 58.6957C6.89259 55.927 7.84365 51.1456 5.77305 47.2911C3.33685 42.7559 5.66865 37.1265 10.5981 35.6423C14.7879 34.3809 17.4963 30.3275 17.0583 25.9739C16.5431 20.8517 20.8517 16.5431 25.9739 17.0583C30.3275 17.4963 34.3809 14.7879 35.6423 10.5981C37.1265 5.66865 42.7559 3.33685 47.2911 5.77305Z"
        stroke="#19191A"
        strokeOpacity="0.100"
      />
    </svg>
  );
}
