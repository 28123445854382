export function Linkdin() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.61267 4.38788C10.7765 4.38788 11.8926 4.85019 12.7155 5.67311C13.5384 6.49603 14.0007 7.61215 14.0007 8.77594V13.8953H11.0754V8.77594C11.0754 8.38801 10.9213 8.01597 10.6469 7.74166C10.3726 7.46736 10.0006 7.31325 9.61267 7.31325C9.22474 7.31325 8.8527 7.46736 8.57839 7.74166C8.30409 8.01597 8.14998 8.38801 8.14998 8.77594V13.8953H5.22461V8.77594C5.22461 7.61215 5.68692 6.49603 6.50984 5.67311C7.33276 4.85019 8.44888 4.38788 9.61267 4.38788Z"
        fill="currentcolor"
      />
      <path
        d="M2.92537 5.11932H0V13.8954H2.92537V5.11932Z"
        fill="currentcolor"
      />
      <path
        d="M1.46269 2.92537C2.27051 2.92537 2.92537 2.27051 2.92537 1.46269C2.92537 0.654867 2.27051 0 1.46269 0C0.654867 0 0 0.654867 0 1.46269C0 2.27051 0.654867 2.92537 1.46269 2.92537Z"
        fill="currentcolor"
      />
    </svg>
  );
}

export function Twitter() {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 0.00639908C13.3906 0.436248 12.7159 0.765014 12.0018 0.980036C11.6186 0.539361 11.1092 0.227021 10.5427 0.0852612C9.9761 -0.0564989 9.37968 -0.0208401 8.83405 0.187415C8.28842 0.39567 7.81992 0.766473 7.4919 1.24967C7.16388 1.73287 6.99217 2.30516 7 2.88913V3.52549C5.88168 3.55449 4.77354 3.30646 3.77428 2.8035C2.77502 2.30054 1.91566 1.55826 1.27273 0.642763C1.27273 0.642763 -1.27273 6.37004 4.45455 8.91549C3.14397 9.80511 1.58274 10.2512 0 10.1882C5.72727 13.37 12.7273 10.1882 12.7273 2.87004C12.7267 2.69278 12.7096 2.51596 12.6764 2.34185C13.3258 1.70135 13.7842 0.892671 14 0.00639908Z"
        fill="currentcolor"
      />
    </svg>
  );
}

export function Instagram() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 1H4C2.34315 1 1 2.34315 1 4V10C1 11.6569 2.34315 13 4 13H10C11.6569 13 13 11.6569 13 10V4C13 2.34315 11.6569 1 10 1Z"
        stroke="currentcolor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.39611 6.62194C9.47016 7.12128 9.38487 7.63127 9.15237 8.07934C8.91987 8.52742 8.552 8.89078 8.10109 9.11774C7.65018 9.3447 7.13918 9.42369 6.64078 9.34349C6.14239 9.2633 5.68197 9.02799 5.32502 8.67103C4.96806 8.31408 4.73275 7.85366 4.65255 7.35526C4.57236 6.85687 4.65135 6.34587 4.87831 5.89496C5.10527 5.44405 5.46862 5.07618 5.9167 4.84368C6.36478 4.61118 6.87477 4.52589 7.37411 4.59994C7.88347 4.67547 8.35502 4.91281 8.71913 5.27692C9.08323 5.64103 9.32058 6.11258 9.39611 6.62194Z"
        stroke="currentcolor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.2974 3.69983H10.3074"
        stroke="currentcolor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Facebook() {
  return (
    <svg
      width="8"
      height="16"
      viewBox="0 0 8 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0H5.81818C4.85376 0 3.92883 0.383116 3.24688 1.06507C2.56493 1.74702 2.18182 2.67194 2.18182 3.63636V5.81818H0V8.72727H2.18182V15.6234H5.09091V8.72727H7.27273L8 5.81818H5.09091V3.63636C5.09091 3.44348 5.16753 3.25849 5.30392 3.1221C5.44031 2.98571 5.6253 2.90909 5.81818 2.90909H8V0Z"
        fill="currentcolor"
      />
    </svg>
  );
}

export function InstagramTwo() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.25 1.5H4.75C2.95507 1.5 1.5 2.95507 1.5 4.75V11.25C1.5 13.0449 2.95507 14.5 4.75 14.5H11.25C13.0449 14.5 14.5 13.0449 14.5 11.25V4.75C14.5 2.95507 13.0449 1.5 11.25 1.5Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6016 7.5907C10.6818 8.13166 10.5894 8.68414 10.3375 9.16955C10.0856 9.65497 9.68711 10.0486 9.19862 10.2945C8.71014 10.5404 8.15656 10.6259 7.61663 10.5391C7.0767 10.4522 6.57791 10.1972 6.19121 9.81055C5.80451 9.42385 5.54959 8.92506 5.46271 8.38513C5.37583 7.8452 5.46141 7.29163 5.70728 6.80314C5.95315 6.31465 6.34679 5.91613 6.83221 5.66425C7.31763 5.41238 7.87011 5.31998 8.41107 5.4002C8.96287 5.48202 9.47372 5.73915 9.86817 6.1336C10.2626 6.52804 10.5197 7.0389 10.6016 7.5907Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5742 4.42578H11.5842"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Dribble() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.50589 12.7494C4.57662 16.336 9.16278 17.5648 12.7494 15.4941C14.2113 14.65 15.2816 13.388 15.8962 11.9461C16.7895 9.85066 16.7208 7.37526 15.4941 5.25063C14.2674 3.12599 12.1581 1.82872 9.89669 1.55462C8.34063 1.366 6.71259 1.66183 5.25063 2.50589C1.66403 4.57662 0.435172 9.16278 2.50589 12.7494Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M12.7127 15.4292C12.7127 15.4292 12.0086 10.4867 10.5011 7.87559C8.99362 5.26451 5.28935 2.57155 5.28935 2.57155M5.68449 15.6124C6.79553 12.2606 12.34 8.54524 16.3975 9.43537M12.311 2.4082C11.1953 5.72344 5.75732 9.38453 1.71875 8.58915"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}

export function Behance() {
  return (
    <svg
      width="18"
      height="11"
      viewBox="0 0 18 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 5.5715H6.33342C7.62867 5.5715 8.61917 6.56199 8.61917 7.85725C8.61917 9.15251 7.62867 10.143 6.33342 10.143H1.76192C1.30477 10.143 1 9.83823 1 9.38108V1.76192C1 1.30477 1.30477 1 1.76192 1H5.5715C6.86676 1 7.85725 1.99049 7.85725 3.28575C7.85725 4.58101 6.86676 5.5715 5.5715 5.5715H1Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M10.9062 7.09454H17.0016C17.0016 5.41832 15.6301 4.04688 13.9539 4.04688C12.2777 4.04688 10.9062 5.41832 10.9062 7.09454ZM10.9062 7.09454C10.9062 8.77076 12.2777 10.1422 13.9539 10.1422H15.2492"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.1125 1.44434H11.668"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Youtube() {
  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.75 13H5.25C3 13 1.5 11.5 1.5 9.25V4.75C1.5 2.5 3 1 5.25 1H12.75C15 1 16.5 2.5 16.5 4.75V9.25C16.5 11.5 15 13 12.75 13Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.70676 5.14837L10.8006 6.40465C11.5543 6.90716 11.5543 7.66093 10.8006 8.16344L8.70676 9.41972C7.86923 9.92224 7.19922 9.50348 7.19922 8.5822V6.06964C7.19922 4.98086 7.86923 4.64585 8.70676 5.14837Z"
        fill="currentColor"
      />
    </svg>
  );
}
