

export default function Dots() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="17" cy="3" r="3" fill="currentcolor" />
      <circle cx="3" cy="3" r="3" fill="currentcolor" />
      <circle cx="3" cy="17" r="3" fill="currentcolor" />
      <circle cx="17" cy="17" r="3" fill="currentcolor" />
    </svg>
  );
}
