export function FirstBracket() {
  return (
    <svg
      width="13"
      height="44"
      viewBox="0 0 13 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5117 42.6396C11.0306 41.4515 9.77734 40.0924 8.75195 38.5625C7.71029 37.0326 6.87207 35.3887 6.2373 33.6309C5.58626 31.8568 5.1224 29.9932 4.8457 28.04C4.55273 26.0869 4.40625 24.0768 4.40625 22.0098C4.40625 19.9264 4.55273 17.8919 4.8457 15.9062C5.13867 13.9206 5.61882 12.0407 6.28613 10.2666C6.93717 8.47624 7.7998 6.81608 8.87402 5.28613C9.93197 3.73991 11.2259 2.36458 12.7559 1.16016L12.0967 0.500977C10.4202 1.68913 8.86589 3.06445 7.43359 4.62695C6.0013 6.18945 4.76432 7.8903 3.72266 9.72949C2.68099 11.5524 1.86719 13.5055 1.28125 15.5889C0.695312 17.6559 0.402344 19.7962 0.402344 22.0098C0.402344 24.1908 0.687174 26.3066 1.25684 28.3574C1.8265 30.3919 2.62402 32.3206 3.64941 34.1436C4.65853 35.9665 5.86296 37.651 7.2627 39.1973C8.66243 40.7435 10.1924 42.1107 11.8525 43.2988L12.5117 42.6396Z"
        fill="#4F4F56"
      />
    </svg>
  );
}

export function FirstBracketTwo() {
  return (
    <svg
      width="13"
      height="44"
      viewBox="0 0 13 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.488281 42.6396C1.9694 41.4515 3.22266 40.0924 4.24805 38.5625C5.28971 37.0326 6.12793 35.3887 6.7627 33.6309C7.41374 31.8568 7.8776 29.9932 8.1543 28.04C8.44727 26.0869 8.59375 24.0768 8.59375 22.0098C8.59375 19.9264 8.44727 17.8919 8.1543 15.9062C7.86133 13.9206 7.38118 12.0407 6.71387 10.2666C6.06283 8.47624 5.2002 6.81608 4.12598 5.28613C3.06803 3.73991 1.77409 2.36458 0.244141 1.16016L0.90332 0.500977C2.57975 1.68913 4.13411 3.06445 5.56641 4.62695C6.9987 6.18945 8.23568 7.8903 9.27734 9.72949C10.319 11.5524 11.1328 13.5055 11.7188 15.5889C12.3047 17.6559 12.5977 19.7962 12.5977 22.0098C12.5977 24.1908 12.3128 26.3066 11.7432 28.3574C11.1735 30.3919 10.376 32.3206 9.35059 34.1436C8.34147 35.9665 7.13704 37.651 5.7373 39.1973C4.33757 40.7435 2.80762 42.1107 1.14746 43.2988L0.488281 42.6396Z"
        fill="#4F4F56"
      />
    </svg>
  );
}
