import React from "react";

export default function BallRound() {
  return (
    <svg
      width="120"
      height="54"
      viewBox="0 0 120 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M57.5 27C57.5 41.6025 44.7744 53.5 29 53.5C13.2256 53.5 0.5 41.6025 0.5 27C0.5 12.3975 13.2256 0.5 29 0.5C44.7744 0.5 57.5 12.3975 57.5 27Z"
        stroke="white"
      />
      <path
        d="M73.5 27C73.5 41.6025 60.7744 53.5 45 53.5C29.2256 53.5 16.5 41.6025 16.5 27C16.5 12.3975 29.2256 0.5 45 0.5C60.7744 0.5 73.5 12.3975 73.5 27Z"
        stroke="white"
      />
      <path
        d="M89.5 27C89.5 41.6025 76.7744 53.5 61 53.5C45.2256 53.5 32.5 41.6025 32.5 27C32.5 12.3975 45.2256 0.5 61 0.5C76.7744 0.5 89.5 12.3975 89.5 27Z"
        stroke="white"
      />
      <path
        d="M103.5 27C103.5 41.6025 90.7744 53.5 75 53.5C59.2256 53.5 46.5 41.6025 46.5 27C46.5 12.3975 59.2256 0.5 75 0.5C90.7744 0.5 103.5 12.3975 103.5 27Z"
        stroke="white"
      />
      <path
        d="M119.5 27C119.5 41.6025 106.774 53.5 91 53.5C75.2256 53.5 62.5 41.6025 62.5 27C62.5 12.3975 75.2256 0.5 91 0.5C106.774 0.5 119.5 12.3975 119.5 27Z"
        stroke="white"
      />
    </svg>
  );
}
